import React from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../constants';

const MaxWidthWrapper = styled.div`
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5em;
  padding-right: 1.5em;
`;

export default MaxWidthWrapper;
